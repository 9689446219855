import styled from 'styled-components'
import Link from 'next/link'

const BlockLogoGLB = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1199px) {
    width: 60px;
    height: 60px;
    margin: -2.5px 0px 0px 1.5px;
  }
  @media screen and (max-width: 479px) {
    width: 50px;
    height: 50px;
    margin: 0px 0px 0px 2.5px;
  }
`
const WrapLogo = styled.img`
  position: relative;
  z-index: 1;
  width: 40px;
  height: 40px;
  object-fit: contain;
`

const WrapLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const LogoGLB = () => {
  return (
    <BlockLogoGLB>
      <WrapLogo src="/logo.png" alt="" width={0} height={0} sizes="100vw" />
      <WrapLink href="/" />
    </BlockLogoGLB>
  )
}

export default LogoGLB
