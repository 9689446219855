import axios from 'axios'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import type { ReactNode } from 'react'
import { createContext, useCallback, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'

import { USER_API, USER_PUBLIC_API } from 'config/constants/endpoints'
import { useFetchPriceTokenList } from 'state/token/hooks'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'config/constants/auth'
import { useFetchUserInfo, useFetchUserWallets, useUser } from 'state/user/hooks'

import { useToast } from '@pancakeswap/uikit'
import type { AuthValuesType, ErrCallbackType, LoginParams, SignUpParams } from './types'

// ** Defaults
const defaultProvider: AuthValuesType = {
  loading: true,
  user: null,
  refresh: () => Promise.resolve(),
  login: () => Promise.resolve(),
  validateEmail: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  logout: () => Promise.resolve(),
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [isLoading, setIsLoading] = useState(true)

  // ** Hooks
  const { mutate } = useSWRConfig()
  const { toastSuccess, toastError } = useToast()
  const user = useUser()
  const { onClearUserInfo, onSetUserInfo } = useFetchUserInfo()
  const { isLoading: isLoadingUserWallets, refresh: refreshWallets } = useFetchUserWallets(user?.user?.username)
  const { isLoading: isLoadingPrice } = useFetchPriceTokenList(!!user?.user)
  const loading = isLoading || isLoadingUserWallets || isLoadingPrice
  const clearCache = useCallback(() => {
    mutate(
      (key) => typeof key === 'object' && key?.[0] && typeof key?.[0] === 'string' && key?.[0].startsWith('0x'),
      undefined,
      { revalidate: false },
    )
  }, [mutate])

  useEffect(() => {
    const setInterceptors = () => {
      axios.interceptors.response.use(
        (response) => {
          return response.data
        },
        async (err) => {
          const prevRequest = err?.config
          if (err.response) {
            // Access Token was expired
            const data = err.response?.data
            if (
              err.response.status === 401 &&
              data?.statusCode === 5100 &&
              // eslint-disable-next-line no-underscore-dangle
              !prevRequest._retry
            ) {
              // eslint-disable-next-line no-underscore-dangle
              prevRequest._retry = true

              try {
                let headers = {}
                const refreshToken = getCookie(REFRESH_TOKEN_KEY)
                axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`
                const res = await axios.post(`${USER_API}/refresh`)
                const { accessToken } = res.data
                setCookie(ACCESS_TOKEN_KEY, accessToken)
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
                headers = {
                  ...prevRequest.headers,
                  Authorization: `Bearer ${accessToken}`,
                }

                return await axios({
                  ...prevRequest,
                  headers,
                  sent: true,
                })
              } catch (_error) {
                console.log('_error', _error)
                onClearUserInfo()

                deleteCookie(ACCESS_TOKEN_KEY)
                deleteCookie(REFRESH_TOKEN_KEY)
                deleteCookie('userData')

                throw _error
              }
            }
          }

          if (err.response && err.response.data) {
            throw err.response.data
          }
          throw err
        },
      )
    }

    const initAuth = async (): Promise<void> => {
      if (isLoading === false) return

      setInterceptors()
      const accessToken = getCookie(ACCESS_TOKEN_KEY)
      const userData = getCookie('userData')
      if (accessToken && userData) {
        setIsLoading(true)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

        onSetUserInfo()
          .then(() => {
            setIsLoading(false)
          })
          .catch(() => {
            onClearUserInfo()

            deleteCookie(ACCESS_TOKEN_KEY)
            deleteCookie(REFRESH_TOKEN_KEY)
            deleteCookie('userData')
          })
      } else {
        setIsLoading(false)
      }
    }

    initAuth()
  }, [isLoading, onClearUserInfo, onSetUserInfo])

  const handleLogin = (body: LoginParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(`${USER_API}/login`, body)
      .then(async (res) => {
        const { accessToken, refreshToken, userData, gg2FAToken } = res.data
        if (accessToken) setCookie(ACCESS_TOKEN_KEY, accessToken)
        setCookie(REFRESH_TOKEN_KEY, refreshToken)
        if (userData) setCookie('userData', JSON.stringify(userData))

        axios.defaults.headers.common.Authorization = `Bearer ${accessToken || gg2FAToken}`

        onSetUserInfo()
      })
      .catch((err: any) => {
        toastError(err?.message)

        return errorCallback ? errorCallback(err?.errors) : null
      })
  }

  const handleSignUp = (body: SignUpParams, successCallback: (_none: string) => void) => {
    axios
      .post(`${USER_PUBLIC_API}/sign-up`, body)
      .then((res) => {
        const none = res?.data?.none || ''
        return successCallback(none)
      })
      .catch((err: any) => {
        toastError(err?.message)
      })
  }

  const handleValidateEmail = async (body: { code: string }, errorCallback?: ErrCallbackType) => {
    return axios
      .post(`${USER_PUBLIC_API}/forgot`, body)
      .then((res: any) => {
        toastSuccess(res?.message)
      })
      .catch((err: any) => {
        toastError(err?.message)

        return errorCallback && errorCallback(err?.errors)
      })
  }

  const handleLogout = useCallback(
    (clearAll = true) => {
      onClearUserInfo()
      if (clearAll) clearCache()

      deleteCookie(ACCESS_TOKEN_KEY)
      deleteCookie(REFRESH_TOKEN_KEY)
      deleteCookie('userData')
    },
    [clearCache, onClearUserInfo],
  )

  const values = {
    user,
    loading,
    refresh: () => {
      onSetUserInfo()
      refreshWallets()
    },
    login: handleLogin,
    signUp: handleSignUp,
    validateEmail: handleValidateEmail,
    logout: handleLogout,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
