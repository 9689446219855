import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: SUISSE_REGULAR;
    src: url("/fonts/Satoshi-Regular.otf");
  }
  @font-face {
    font-family: SUISSE_MEDIUM;
    src: url("/fonts/Satoshi-Medium.otf");
  }
  @font-face {
    font-family: SUISSE_BOLD;
    src: url("/fonts/Satoshi-Bold.otf");
  }
  @font-face {
    font-family: SUISSE_BLACK;
    src: url("/fonts/Satoshi-Black.otf");
  }

  * {
    font-family: SUISSE_MEDIUM;
  }

  html {
    font-size: 62.5%;
  }

  html, body {
    background: ${({ theme }) => theme.colors.background};
    scroll-behavior: smooth;
  }
  
  body {
    background-color: ${({ theme }) => theme.colors.background};
    font-family: SUISSE_REGULAR;
    font-size: 1.4rem;
    line-height: 1.75rem;
    color: #000;
    min-height: -webkit-fill-available !important;
    overflow-y : auto;
    overflow-x: hidden;
    img {
      height: auto;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1199px) {
    .wrapContent {
      padding : 0px 0px 150px 0px !important;
    }
    .blockContainer {
      max-width: unset !important;
      min-width: unset !important;
      margin: 0px !important;
      padding: 0px 15px !important;
    }
  }

  .blockContainer {
    max-width: 1300px;
    min-width: 800px;
    margin: 0 auto;
    padding: 0px 15px;
  }

  .wrapContent {
    padding : 0px 0px 100px 70px;
  }

  #__next {
    position: relative;
    z-index: 1;
  }

  #portal-root {
    position: relative;
    z-index: 2;
  }

  canvas {
    font-family : SUISSE_BOLD !important;
  }
  /* CUSTOM DATE PICKER  */
 

@media screen and (max-width : 1199px) {
  .rs-picker-daterange-calendar-group {
    min-width: unset !important;
    height: auto !important;
  }

  .rs-picker-daterange-panel {
    min-width: unset !important;
  }

  .rs-picker-daterange-calendar-group {
    display: flex;
    flex-direction: column;
  }

  .rs-picker-popup .rs-calendar {
    width: fit-content !important;
  }

  .rs-picker-daterange-menu .rs-calendar:first-child {
    border: none !important;
  }

  .rs-stack-item {
    width: 100%;
    max-width: 275px;
  }

  .rs-btn-primary {
    color: var(--textMainLight) !important;
  }

  .grecaptcha-badge {
    opacity: 0.3;
  }

 
}

@keyframes progressLoad {
    0% {
      left: -50%;
      opacity: 1;
    }
    100% {
        left: 150%;
        opacity: 0;
    }
  }

  @keyframes shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px)}
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
  }
  @keyframes updown {
    from { transform: translateY(-10px) }
    to { transform: translateY(10px) }
  }
`



export default GlobalStyle
