import { useDispatch, useSelector } from 'react-redux'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

import { FetchStatus } from 'config/constants/types'

import type { AppState } from '..'
import { savePriceTokenList } from './actions'
import { fetchPriceTokenList, fetchTokenHistory } from './helpers'
import type { TokenType } from './types'

export const useGetTokenList = () => {
  return useSelector<AppState, { [key: string]: TokenType }>((state) => {
    return state.token.tokens
  })
}

export const useGetTokenPrice = (code: string) => {
  return useSelector<AppState, TokenType>((state) => {
    return state.token.tokens?.[code]
  })
}

export function useFetchPriceTokenList(_canFetch: boolean) {
  const dispatch = useDispatch()

  const { status } = useSWR('TokenList', async () => {
    const tokens = await fetchPriceTokenList()
    dispatch(savePriceTokenList({ tokens }))

    return tokens
  })

  return { isLoading: status === FetchStatus.Fetching }
}

export function useFetchTokenHistory(code: string) {
  const { data, status } = useSWRImmutable(code ? [code, 'TokenHistory'] : null, async () => {
    return fetchTokenHistory(code)
  })

  return {
    isLoading: status === FetchStatus.Fetching,
    history: data ?? [],
  }
}
