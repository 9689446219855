import styled from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;
  width: 100%;
  z-index: 10;
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  @media screen and (max-width: 1199px) {
    padding: 7.5px 5px;
    margin: 0px;
  }
  width: 100%;
  padding: 10px 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.4s all;
  margin: 0px 0px 7.5px 0px;
  & > div {
    display: flex;
    flex-direction: column-reverse;
  }
  &:hover {
    filter: brightness(0.9);
  }
  p {
    margin: 6.5px 0px 0px 0px;
    color: ${({ theme, $isActive }) => ($isActive ? "rgb(242,187,66)" : "#CDCDCD")};
    font-family: SUISSE_BOLD;
  }
  svg {
    width: 22px;
    fill: ${({ theme, $isActive }) => ($isActive ? "rgb(242,187,66)" : "#CDCDCD")};
  }
`;

export const StyledName = styled.div`
  user-select: none;
  p {
    font-family: SUISSE_BOLD;
    font-size: 1rem;
    line-height: calc(1rem * 1.25);
  }
`;

export default StyledMenuItem;
