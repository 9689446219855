import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M23.855 8.074c-1.128-4.185-4.818-6.978-9.236-7.827 1.462 2.336 3.273 5.914 3.376 9.439C18.594 9.315 19.312 9 20 9c.742 0 1.277.444 1.619.865a.661.661 0 0 1-.061.885l-5.886 5.759a2.994 2.994 0 0 0-1.016-.437l1.499-5.509C16.154 6.714 13.415 1.951 12.05 0h-.1c-1.366 1.95-4.104 6.713-4.104 10.564l1.499 5.509a2.966 2.966 0 0 0-1.016.437l-5.886-5.759a.66.66 0 0 1-.061-.885c.341-.421.876-.865 1.619-.865.688 0 1.406.315 2.005.686C6.109 6.162 7.92 2.583 9.382.248 4.963 1.096 1.273 3.889.145 8.074c-.463 1.718.086 3.554 1.357 4.798l5.626 5.26A2.998 2.998 0 0 0 7 19v2a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3v-2c0-.302-.045-.593-.128-.868l5.626-5.26c1.272-1.244 1.82-3.081 1.357-4.798Zm-9.923 2.289L12.568 16h-1.137l-1.364-5.637a.847.847 0 0 1 .435-.96c.476-.235.995-.403 1.497-.403s1.02.168 1.497.403a.846.846 0 0 1 .435.96Z" />
      </g>
    </Svg>
  );
};

export default Icon;
