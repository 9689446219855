import { createReducer } from "@reduxjs/toolkit";

import { savePriceTokenList } from "./actions";
import type { TokenState } from "./types";

const initialState: TokenState = {
  tokens: {},
};

export default createReducer(initialState, (builder) =>
  builder.addCase(savePriceTokenList, (state, { payload: { tokens } }) => {
    const newTokens = tokens.reduce(
      (acc, token) => ({ ...acc, [token.code]: token }),
      {}
    );
    return { ...state, tokens: newTokens };
  })
);
