import axios from 'axios'

import { USER_API, USER_PUBLIC_API } from 'config/constants/endpoints'
import { ResponsePaging } from 'config/constants/types'

import type { UserInfoType, UserProfileType, UserReferrerListType, UserWalletType } from './types'

export const getUserInfo = async (): Promise<UserInfoType> => {
  const response = await axios.get(`${USER_API}/profile`)

  return response?.data
}

// use this for update user info
export const getUserProfile = async (): Promise<UserProfileType> => {
  const response = await axios.get(`${USER_API}/profile`)

  return response?.data
}

export const sendEmailValidation = async (body: any): Promise<string> => {
  const response = await axios.post(`${USER_API}/send-email-code`, body)

  return response?.data?.timeRetry
}

export const checkEmailValidation = async (
  code: string,
): Promise<{
  amount: string
  symbol: string
}> => {
  const response = await axios.get(`${USER_API}/activate/${code}`)

  return response?.data
}

export const changeUserInfo = async (body: any): Promise<UserInfoType> => {
  const formData = new FormData()
  Object.keys(body).forEach((field) => {
    formData.append(field, body[field])
  })

  const response = await axios.patch(`${USER_API}/update`, formData)

  return response?.data
}

export const getUserWallets = async (): Promise<{
  wallets: UserWalletType[]
}> => {
  const response = await axios.get(`${USER_API}/wallets`)

  return response?.data
}

export const getUserWalletByCode = async (code: string): Promise<UserWalletType> => {
  const response = await axios.get(`${USER_API}/wallet/${code}`)

  return response?.data
}

export const requestSell = async (body: any) => {
  const response = await axios.post(`${USER_API}/sell`, body)

  return response?.data
}

export const fetchAllReferrers = async (email: string, params: any): Promise<ResponsePaging<UserReferrerListType>> => {
  try {
    const response = await axios.get<any, any>(`${USER_API}/referrer/list/${email}`, {
      params,
    })

    return response
  } catch (error) {
    console.error('Get Referrers List Failed', error)
    const defaultPaging: ResponsePaging<UserReferrerListType> = {
      totalData: 0,
      totalPage: 0,
      currentPage: 0,
      perPage: 0,
      data: [],
    }

    return defaultPaging
  }
}

export const requestClaimProfit = async (projectId: string): Promise<UserProfileType> => {
  const response = await axios.get(`${USER_API}/claim/${projectId}`)

  return response?.data
}

export const checkCanCreateUser = async (): Promise<boolean> => {
  try {
    const response = await axios.get(`${USER_API}/can-create-user`)

    return response?.data?.canCreateUser ?? false
  } catch (error) {
    return false
  }
}

export const requestSetup2FA = async (): Promise<{
  otpUrl: string
  secret: string
}> => {
  try {
    const response = await axios.get(`${USER_API}/gg-2fa/setup`)

    return response?.data ?? null
  } catch (error) {
    return null
  }
}

export const requestGG2FAEnabled = async (code: string): Promise<boolean> => {
  return axios.post(`${USER_API}/gg-2fa/enable`, { code })
}

export const requestGG2FADisabled = async (code: string): Promise<boolean> => {
  return axios.post(`${USER_API}/gg-2fa/disabled`, { code })
}

export const requestGG2FAValid = async (code: string): Promise<any> => {
  return axios.post(`${USER_API}/gg-2fa/valid`, { code })
}

export const requestValidateAccount = async (activeToken: string): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await fetch(`${USER_API}/activate/${activeToken}`)
    const res = await response.json()

    return {
      success: res?.statusCode === 200,
      message: res?.message,
    }
  } catch (error) {
    return {
      success: false,
      message: 'Cannot validate account. Please try again.',
    }
  }
}

export const requestResendActiveToken = async (email: string) => {
  return axios.get(`${USER_PUBLIC_API}/resend-active-token/${email}`)
}

export const fetchReferrerOf = async (
  address: string,
): Promise<{
  referrer: string
  exceptSetRef: boolean
}> => {
  try {
    const response = await axios.get(`${USER_API}/referrer-of/${address}`)

    return response?.data || { referrer: '', exceptSetRef: false }
  } catch (error) {
    return { referrer: '', exceptSetRef: false }
  }
}

export const requestSetReferrer = async (address: string): Promise<any> => {
  return axios.post(`${USER_API}/set-referrer/${address}`)
}

export const fetchTotalBuyNFT = async (): Promise<{
  totalBuyNftF1: number
  totalBuyNftFromF2: number
}> => {
  const response = await axios.get(`${USER_API}/total-buy-nft-downline`)

  return response?.data
}
