import { usePreloadImages } from '@pancakeswap/hooks'
import { useTranslation } from '@pancakeswap/localization'
import { AtomBox } from '@pancakeswap/ui/components/AtomBox'
import {
  Button,
  Heading,
  Image,
  ModalV2,
  ModalV2Props,
  ModalWrapper,
  MoreHorizontalIcon,
  SvgProps,
  Text,
} from '@pancakeswap/uikit'
import { atom, useAtom } from 'jotai'
import { lazy, PropsWithChildren, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  desktopWalletSelectionClass,
  modalWrapperClass,
  walletIconClass,
  walletSelectWrapperClass,
  imgConnect,
  nameWallet,
  itemButton,
  recently,
} from './WalletModal.css'

type LinkOfTextAndLink = string | { text: string; url: string }

type DeviceLink = {
  desktop?: LinkOfTextAndLink
  mobile?: LinkOfTextAndLink
}

type LinkOfDevice = string | DeviceLink

export type WalletConfigV2<T = unknown> = {
  id: string
  title: string
  icon: string | React.FC<React.PropsWithChildren<SvgProps>>
  connectorId: T
  deepLink?: string
  installed?: boolean
  guide?: LinkOfDevice
  downloadLink?: LinkOfDevice
  mobileOnly?: boolean
  qrCode?: () => Promise<string>
}

interface WalletModalV2Props<T = unknown> extends ModalV2Props {
  wallets: WalletConfigV2<T>[]
  login: (connectorId: T) => Promise<any>
  docLink: string
  docText: string
  onLoginUser?: (account: string) => void
}

export class WalletConnectorNotFoundError extends Error { }

export class WalletSwitchChainError extends Error { }

const errorAtom = atom<string>('')

const selectedWalletAtom = atom<WalletConfigV2<unknown> | null>(null)

export function useSelectedWallet<T>() {
  // @ts-ignore
  return useAtom<WalletConfigV2<T> | null>(selectedWalletAtom)
}

const TabContainer = ({ children }: PropsWithChildren<{ docLink: string; docText: string }>) => {
  const [index] = useState(0)

  return (
    <AtomBox position="relative" zIndex="modal" className={modalWrapperClass}>
      <AtomBox position="absolute" style={{ top: '-50px' }} />
      <AtomBox
        display="flex"
        position="relative"
        background="gradientCardHeader"
        borderRadius="card"
        borderBottomRadius={{
          xs: '0',
          md: 'card',
        }}
        zIndex="modal"
        width="fit-content"
      >
        {index === 0 && children}
      </AtomBox>
    </AtomBox>
  )
}

const MOBILE_DEFAULT_DISPLAY_COUNT = 8

function MobileModal<T>({
  wallets,
  connectWallet,
}: Pick<WalletModalV2Props<T>, 'wallets' | 'docLink' | 'docText'> & {
  connectWallet: (wallet: WalletConfigV2<T>) => void
}) {
  const { t } = useTranslation()

  const installedWallets: WalletConfigV2<T>[] = wallets.filter((w) => w.installed)
  const walletsToShow: WalletConfigV2<T>[] = wallets.filter((w) => {
    if (installedWallets.length) {
      return w.installed
    }
    return w.installed !== false || w.deepLink
  })

  return (
    <AtomBox
      width="420px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      bg="white"
      py="24px"
      px="24px"
      zIndex="modal"
      borderRadius="card"
      className={desktopWalletSelectionClass}
    >
      <AtomBox px="0px">
        <Heading color="#000">{t('Connect Wallet')}</Heading>
      </AtomBox>
      <img className={imgConnect} src="/logo.png" alt="" />
      <WalletSelect
        displayCount={MOBILE_DEFAULT_DISPLAY_COUNT}
        wallets={walletsToShow}
        onClick={(wallet) => {
          connectWallet(wallet)
          if (wallet.deepLink && wallet.installed === false) {
            window.open(wallet.deepLink)
          }
        }}
      />
    </AtomBox>
  )
}

function WalletSelect<T>({
  wallets,
  onClick,
  displayCount = 9,
}: {
  wallets: WalletConfigV2<T>[]
  onClick: (wallet: WalletConfigV2<T>) => void
  displayCount?: number
}) {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const walletDisplayCount = wallets.length > displayCount ? displayCount - 1 : displayCount
  const walletsToShow = showMore ? wallets : wallets.slice(0, walletDisplayCount)
  return (
    <AtomBox
      display="flex"
      flexDirection="column"
      overflowY="auto"
      width="100%"
      overflowX="hidden"
      py="0px"
      px="0px"
      className={walletSelectWrapperClass}
    >
      {walletsToShow.map((wallet: any) => {
        const isImage = typeof wallet.icon === 'string'
        const Icon = wallet.icon

        return (
          <Button
            key={wallet.id}
            variant="text"
            height="auto"
            as={AtomBox}
            display="flex"
            flex-direction="row"
            alignItems="center"
            width="100%"
            className={itemButton}
            style={{ justifyContent: 'flex-start', letterSpacing: 'normal', padding: '0' }}
            onClick={() => onClick(wallet)}
          >
            <AtomBox borderRadius="12px" position="relative" zIndex="10">
              <AtomBox
                bgc="dropdown"
                display="flex"
                position="relative"
                justifyContent="center"
                alignItems="center"
                className={walletIconClass}
                style={{ borderRadius: '13px' }}
              >
                {isImage ? (
                  <Image src={Icon as string} width={50} height={50} />
                ) : (
                  <Icon width={24} height={24} color="textSubtle" />
                )}
              </AtomBox>
            </AtomBox>
            {wallet.installed && <AtomBox className={recently}>Recently</AtomBox>}
            <Heading className={nameWallet}>{wallet.title}</Heading>
          </Button>
        )
      })}
      {!showMore && wallets.length > walletDisplayCount && (
        <AtomBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Button height="auto" variant="text" as={AtomBox} flexDirection="column" onClick={() => setShowMore(true)}>
            <AtomBox
              className={walletIconClass}
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgc="dropdown"
            >
              <MoreHorizontalIcon color="text" />
            </AtomBox>
            <Text fontSize="12px" textAlign="center" mt="4px">
              {t('More')}
            </Text>
          </Button>
        </AtomBox>
      )}
    </AtomBox>
  )
}

export const walletLocalStorageKey = 'wallet'

const lastUsedWalletNameAtom = atom<string>('')

lastUsedWalletNameAtom.onMount = (set) => {
  const preferred = localStorage?.getItem(walletLocalStorageKey)
  if (preferred) {
    set(preferred)
  }
}

function sortWallets<T>(wallets: WalletConfigV2<T>[], lastUsedWalletName: string | null) {
  const sorted = [...wallets].sort((a, b) => {
    if (a.installed === b.installed) return 0
    return a.installed === true ? -1 : 1
  })

  if (!lastUsedWalletName) {
    return sorted
  }
  const foundLastUsedWallet = wallets.find((w) => w.title === lastUsedWalletName)
  if (!foundLastUsedWallet) return sorted
  return [foundLastUsedWallet, ...sorted.filter((w) => w.id !== foundLastUsedWallet.id)]
}

function DesktopModal<T>({
  wallets: wallets_,
  connectWallet,
  // docLink,
  // docText,
}: Pick<WalletModalV2Props<T>, 'wallets' | 'docLink' | 'docText'> & {
  connectWallet: (wallet: WalletConfigV2<T>) => void
}) {
  const wallets: WalletConfigV2<T>[] = wallets_.filter((w) => {
    return w.installed !== false || (!w.installed && (w.guide || w.downloadLink || w.qrCode))
  })

  const [, setQrCode] = useState<string | undefined>(undefined)
  const { t } = useTranslation()

  const connectToWallet = (wallet: WalletConfigV2<T>) => {
    connectWallet(wallet)
  }

  return (
    <>
      <AtomBox
        width="420px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        bg="white"
        py="24px"
        px="24px"
        zIndex="modal"
        borderRadius="card"
        className={desktopWalletSelectionClass}
      >
        <AtomBox px="0px">
          <Heading color="#000">{t('Connect Wallet')}</Heading>
        </AtomBox>
        <img className={imgConnect} src="/logo.png" alt="" />
        <WalletSelect
          wallets={wallets}
          onClick={(w) => {
            connectToWallet(w)
            setQrCode(undefined)
            if (w.qrCode) {
              w.qrCode().then(
                (uri) => {
                  setQrCode(uri)
                },
                () => {
                  // do nothing.
                },
              )
            }
          }}
        />
      </AtomBox>
    </>
  )
}

export function WalletModalV2<T = unknown>(props: WalletModalV2Props<T>) {
  const { wallets: _wallets, login, docLink, docText, onLoginUser, ...rest } = props

  const [lastUsedWalletName] = useAtom(lastUsedWalletNameAtom)

  const wallets = useMemo(() => sortWallets(_wallets, lastUsedWalletName), [_wallets, lastUsedWalletName])
  const [, setSelected] = useSelectedWallet<T>()
  const [, setError] = useAtom(errorAtom)
  const { t } = useTranslation()

  const imageSources = useMemo(
    () =>
      wallets
        .map((w) => w.icon)
        .filter((icon) => typeof icon === 'string')
        .concat('https://cdn.pancakeswap.com/wallets/wallet_intro.png') as string[],
    [wallets],
  )

  usePreloadImages(imageSources.slice(0, MOBILE_DEFAULT_DISPLAY_COUNT))

  const connectWallet = (wallet: WalletConfigV2<T>) => {
    setSelected(wallet)
    setError('')
    if (wallet.installed !== false) {
      login(wallet.connectorId)
        .then((v) => {
          if (v) {
            localStorage.setItem(walletLocalStorageKey, wallet.title)
            onLoginUser?.(v?.account)
          }
        })
        .catch((err) => {
          if (err instanceof WalletConnectorNotFoundError) {
            setError(t('no provider found'))
          } else if (err instanceof WalletSwitchChainError) {
            setError(err.message)
          } else {
            setError(t('Error connecting, please authorize wallet to access.'))
          }
        })
    }
  }

  return (
    <ModalV2 closeOnOverlayClick disableOutsidePointerEvents={false} {...rest}>
      <ModalWrapper onDismiss={props.onDismiss} style={{ overflow: 'visible', border: 'none' }}>
        <AtomBox position="relative">
          <TabContainer docLink={docLink} docText={docText}>
            {isMobile ? (
              <MobileModal connectWallet={connectWallet} wallets={wallets} docLink={docLink} docText={docText} />
            ) : (
              <DesktopModal connectWallet={connectWallet} wallets={wallets} docLink={docLink} docText={docText} />
            )}
          </TabContainer>
        </AtomBox>
      </ModalWrapper>
    </ModalV2>
  )
}
