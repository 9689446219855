import axios from "axios";

import { TOKEN_PUBLIC_API } from "config/constants/endpoints";

import type { TokenHistoryType, TokenType } from "./types";

export const fetchPriceTokenList = async (): Promise<TokenType[]> => {
  try {
    const response = await axios.get<any, any>(`${TOKEN_PUBLIC_API}/prices`);

    return response?.data?.coins;
  } catch (error) {
    console.error("Get Price Token List Failed", error);

    return [];
  }
};

export const fetchTokenHistory = async (
  code: string
): Promise<TokenHistoryType[]> => {
  try {
    const response = await axios.get<any, any>(
      `${TOKEN_PUBLIC_API}/price-history/${code}`
    );

    return response?.data?.history;
  } catch (error) {
    console.error("Get Token History Failed", error);

    return [];
  }
};
