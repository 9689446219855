import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g><path d="M22 14.556V13c0-2.206-1.794-4-4-4s-4 1.794-4 4v1.556c-1.19.694-2 1.97-2 3.444v2c0 2.206 1.794 4 4 4h4c2.206 0 4-1.794 4-4v-2c0-1.474-.81-2.75-2-3.444ZM18 20.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 18 20.5Zm2-6.5h-4v-1c0-1.103.897-2 2-2s2 .897 2 2v1ZM4.343 11H0c0-2.757 2.243-5 5-5h6V1.636A1.635 1.635 0 0 1 13.329.154l3.2 1.498a1 1 0 0 1 0 1.696L12.999 5v1h6c2.757 0 5 2.243 5 5h-.35c-.826-2.327-3.043-4-5.65-4-.981 0-1.905.241-2.723.66l-.449-.488c-1.511-1.512-4.117-1.542-5.687.03l-2.678 2.919A3.02 3.02 0 0 1 4.341 11ZM10 20v-2a6 6 0 0 1 2-4.448V13c0-1.604.637-3.06 1.666-4.138l-.282-.306c-.696-.695-2.043-.726-2.798.03l-2.678 2.919A4.99 4.99 0 0 1 4.343 13H0v8c0 1.654 1.346 3 3 3h8.54A5.97 5.97 0 0 1 10 20Zm-5 2H4a1 1 0 1 1 0-2h1a1 1 0 1 1 0 2Zm0-4H4a1 1 0 1 1 0-2h1a1 1 0 1 1 0 2Z"></path></g>
    </Svg>
  );
};

export default Icon;
