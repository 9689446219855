import { useAuthServer } from 'contexts/Auth/hooks/useAuthServer'
import { useAccount } from 'wagmi'

export const useLogged = () => {
  const {
    user: { user },
  } = useAuthServer()
  const { address } = useAccount()

  const logged = !!user?.address
  const isWrongAccount = user && address && user?.address?.toLowerCase() !== address?.toLowerCase()

  return {
    logged,
    isWrongAccount,
  }
}
