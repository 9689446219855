import { Box, copyText } from '@pancakeswap/uikit'
import type { PropsWithChildren } from 'react'

interface CopyButtonProps {
  textCopy?: string
  children?: any
  onCopy?: (isCopy: boolean) => void
}

const CopyButton: React.FC<PropsWithChildren<CopyButtonProps>> = ({ textCopy, children, onCopy }) => {
  const handleCopy = () => {
    if (onCopy) {
      onCopy(true)
      copyText(textCopy, () => {
        onCopy(false)
      })
    }
  }

  return <Box onClick={handleCopy}>{children}</Box>
}
export default CopyButton
